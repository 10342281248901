import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const SButton = styled.button`
  display: flex;
  justify-content: center;
  height: 48px;
  padding: 13px 16px;
  align-items: center;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  font-size: "14px";
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1.5px;
  width: fit-content;
  margin: 2rem auto 0;
  background-color: ${p =>
    p.colorTheme === "healthcare"
      ? p.theme.colors.healthcarePrimary
      : "#0368b9"};
  color: white;
  cursor: pointer;
`

const RedButton = styled(SButton)`
  background-color: ${p => p.theme.colors.accent};
`

const Button = ({ children, red, onClick, type, ...props }) => {
  if (red) {
    return (
      <RedButton type={type} onClick={onClick} {...props}>
        {children}
      </RedButton>
    )
  } else {
    return (
      <SButton type={type} onClick={onClick} {...props}>
        {children}
      </SButton>
    )
  }
}

Button.defaultProps = {
  type: "button",
  onClick: () => {},
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default Button
