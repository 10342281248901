import styled from "styled-components"
export { InputWithLabel, FormikInput } from "./input"
export { TextareaWithLabel, FormikText } from "./textarea"
export { FormikTabs } from "./tabs"
export { FormikSelect } from "./select"
export { FormikCheckbox } from "./checkbox"
export { FormikDatepicker } from "./datepicker"

export const InputWrapper = styled.div.attrs(props => ({
  className: props.hasError ? "has-error" : "",
}))`
  position: relative;
  display: flex;

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: ${p => (p.hasError ? "red" : "transparent")};
    transition: border-color 250ms ease-in-out;
  }
`

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
  user-select: none;
`
export const Optional = styled.span`
  text-align: right;
  color: ${p => p.theme.colors.grey};
`

export const Error = styled.div`
  display: block;
  width: 100%;
  min-height: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: red;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
