import axios from "axios"
import { navigate } from "gatsby"
import { dataURItoBlob, dateToString } from "../utils/form-data"

//Prod api
// axios.defaults.baseURL = "https://forms-api.pinpoint-portal.co.uk/api"

// Dev api
axios.defaults.baseURL = "https://pinpoint-forms-api.ne6.dev/api"

// axios.defaults.baseURL =
//   process.env.NODE_ENV === "production"
//     ? "https://forms-api.pinpoint-portal.co.uk/api"
//     : "https://pinpoint-forms-api.ne6.dev/api"


const dateCheck = date => {
  if (date)
    return dateToString(date.toDateString(), "DD/MM/YYYY")
  else return ""
}

const dateOfBirthCheck = () => {
  let currentDate = new Date()
  const eighteenYearsAgo = currentDate.setFullYear(currentDate.getFullYear() - 18);
  return eighteenYearsAgo
}

const FormatFormData = async (data, sigCanvas) => {
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia

  let file

  const dataURL = sigCanvas.current
    .getTrimmedCanvas()
    .toDataURL("image/png")
  const blobContent = dataURItoBlob(dataURL)
  const formData = jsonToFormData(data)

  if (isEdge || isIE) {
    file = new Blob([blobContent], "signature.png", {
      type: "image/png",
    })
  } else {
    file = new File([blobContent], "signature.png", { type: "image/png" })
  }

  formData.append("signature", file)
  return formData
}

const handleSignupResponse = (res, setErrorText) => {
  if (res.error) {
    let error = Object.values(res.error)
    setErrorText(error)
  } else {
    navigate("/form-confirm")
  }
}

const submitForm = async (data, url) => {
  return axios({
    method: "POST",
    url: url,
    data,
  })
    .then(res => res.data)
    .catch(e => {
      return {
        error: { generic: "There was a submitting error, please try again" },
        ...e.response.data,
      }
    })
}

const emailCheck = async data => {
  return axios({
    method: "GET",
    url: "/check-email",
    params: { email: data.email },
  })
    .then(res => res.data)
    .catch(e => {
      return {
        error: { generic: "There was a submitting error, please try again" },
        ...e.response.data,
      }
    })
}

const handleSubmitResponse = (res, setErrorText) => {
  if (res.error) {
    const error = Object.values(res.error)
    setErrorText(error)
  } else {
    navigate("/form-confirm")
  }
}

const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? "" : data

    formData.append(parentKey, value)
  }
}

const jsonToFormData = data => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export {
  submitForm,
  handleSubmitResponse,
  buildFormData,
  jsonToFormData,
  emailCheck,
  FormatFormData,
  handleSignupResponse,
  dateCheck,
  dateOfBirthCheck,
}
