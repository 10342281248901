import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FooterLogo from "../../../assets/images/logo"
import HealthCareLogo from "../../../assets/images/healthcare-logo"

const SFooter = styled.footer`
  width: 100%;
  margin-top: 2rem;
  background-color: #fff;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  max-width: 1440px;
  margin: 0 auto;
  ${p => p.theme.layout.phone} {
    flex-direction: row;
  }
`

const Footer = healthcare => {
  return (
    <SFooter>
      <Wrapper>
        <a href="/">
        {healthcare && healthcare.healthcare ? (
          <HealthCareLogo />
        ) : (
          <FooterLogo />
        )}
        </a>
        <a href="#main">Back to the top</a>
      </Wrapper>
    </SFooter>
  )
}

export default Footer
