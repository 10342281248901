import { parse, isDate } from "date-fns"
import * as moment from "moment"

export const createFormData = raw => {
  const formData = new FormData()
  Object.entries(raw).forEach(
    ([k, v]) => (v || v === false || v === 0) && formData.append(k, v)
  )
  return formData
}

export const appendItemsToFormDataArray = (formData, array, fieldName) =>
  array.forEach((item, i) => formData.append(`${fieldName}[${i}]`, item))

export const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1])
  else byteString = unescape(dataURI.split(",")[1])

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "dd/mm/yyyy", new Date())

  return parsedDate
}

export const dateToString = (dateStr, format) => {
  if (!dateStr) return "No Date"
  if (format) {
    return moment(dateStr).format(format)
  }
  return moment(dateStr).format("D.MM.YYYY")
}

export const switchDateFormat = (date_string) => {
  var date_components = date_string.split("/");
  var day = date_components[0];
  var month = date_components[1];
  var year = date_components[2];
  return new Date(year, month - 1, day);
}