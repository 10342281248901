import { css } from "styled-components"

const accent = "#db3e57"
const bg = "#F6F8FB"
const border = "#bdbdbd"
const disabled = "#595959"
const fail = accent
const grey = "#979797"
const grey_light = "#f0f0f0"
const grey_light_super = "#fafafa"
const primary = `#0344B9`
const success = "#07BB8C"
const text = "#324561"
const healthcarePrimary = "#008578"

export default {
  colors: {
    accent,
    bg,
    border,
    disabled,
    fail,
    grey,
    grey_light,
    grey_light_super,
    primary,
    success,
    text,
    healthcarePrimary,
  },
  style: {
    primary_gradient: "linear-gradient(135deg, #0368B9, #2842DE)",
    primary_gradient__active: "linear-gradient(135deg, #0368B9, #2137b9)",
    mono_gradient: "linear-gradient(135deg, #393C3F, black)",
    box_shadow: `box-shadow: 2px 1px 4px 0 rgba(0,0,0,0.05)`,
    nav_and_header_shadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
  },
  layout: {
    phone: `@media (min-width: 700px)`,
    tablet: `@media (min-width: 800px)`,
    web: `@media (min-width: 1100px)`,
    header_height: "80px",
    nav_mobile_height: "65px",
    nav_tablet_width: "80px",
    nav_web_width: "250px",
    nav_web_link_height: "60px",
  },
  utils: {
    nav_border: none => `4px solid ${none ? "transparent" : accent}`,
    tablet: displayElement => css`
      &[data-tablet] {
        display: none;
      }
      ${p => p.theme.layout.tablet} {
        &[data-tablet] {
          ${displayElement};
        }
      }
    `,
    web: displayElement => css`
      &[data-web] {
        display: none;
      }
      ${p => p.theme.layout.web} {
        &[data-web] {
          ${displayElement};
        }
      }
    `,
  },
}
