import React from "react"

const NavLogo = props => (
  <svg viewBox="0 0 2033 560" height="54" {...props}>
    <path d="M1677 56v40h14c2 0 2 0 2-20 1-17 0-20-1-20h-15zM1972 56c-1 0-2 3-2 39v39h-45v17h46v226h8c8 0 8 0 9-3l1-112-1-111h45v-17h-44V95c0-34-1-39-2-39h-15zM856 57v49l22 1 21-1V56h-21l-22 1z"></path>
    <path
      d="M336 131c1 42 0 50-1 49-2-3-15-10-24-13-16-5-14-5-122-5H87l-1 24v24h98l-2 4c-3 4-8 13-10 20-6 16-5 15-5 124v102h48v-98l11 6 22 9 10 2h103l104 1-1-24v-25h-97l6-12c6-13 9-23 11-38V82h-48v50zm-47 81c10 2 18 7 25 13a56 56 0 0121 45 60 60 0 01-94 50 61 61 0 01-25-63c4-17 16-32 31-40 13-7 28-9 42-5z"
      fill="#0344b9"
    ></path>
    <path d="M1521 127a128 128 0 00-104 84c-3 8-6 25-7 32l-1-6c-7-48-39-88-84-103-13-5-24-6-40-6s-27 1-39 5c-22 8-43 23-59 44l-8 10v-53h-16l-1 6v314l8 1h8l1-67v-66l6 9c16 22 38 39 61 46 30 9 66 7 93-6a120 120 0 0071-103c0-4 0-4 1 2a128 128 0 0098 110c12 3 39 3 50 1 28-6 52-21 68-41a133 133 0 0029-116 125 125 0 00-135-97zm-217 17a113 113 0 0188 107 114 114 0 01-53 103c-42 25-94 18-128-18a118 118 0 01-30-105c8-40 38-74 77-86 15-4 31-5 46-1zm248-1c24 4 42 14 58 30 32 32 41 82 23 127-10 26-28 46-51 57-59 29-129-2-150-66a118 118 0 0155-138c5-4 23-10 31-11 9-1 24-1 34 1zM698 129c-21 2-40 11-58 25l-8 7v-27h-45v320l22 1h22v-53l1-52 5 4c5 5 19 15 28 19 29 13 69 13 99 1 31-12 58-42 69-75 5-16 6-21 6-43s-1-29-6-44c-13-38-44-68-82-78-16-5-38-7-53-5zm21 43c35 5 61 27 71 61a85 85 0 01-31 90 83 83 0 01-52 16 86 86 0 01-76-80 85 85 0 0168-86c10-2 11-2 20-1zM1811 130c-11 1-28 6-37 11s-22 17-30 27l-2 3v-37h-17v243h16v-71l1-83c2-25 8-40 24-55 9-9 19-15 31-19 9-4 9-4 27-4 16 0 19 1 26 3 16 4 31 14 39 27 5 7 11 20 13 28 5 19 5 20 5 99l1 75h7l8-1v-71c1-91 0-102-8-122-6-13-19-29-32-38-6-5-18-10-27-12-12-3-30-5-45-3zM1021 131c-6 0-15 3-21 6-9 4-15 8-23 16l-7 6v-25h-42v243h44l1-67c0-73 0-78 6-96 4-15 14-29 25-35 12-5 28-7 42-5 9 2 20 7 25 12 8 7 14 19 17 33 3 13 3 30 4 95v63h45l-1-80c0-86 0-85-6-105a83 83 0 00-70-61h-39zM856 135v241h44V134h-22l-22 1zM1677 255v122h17V134h-17v121z"></path>
    <circle fill="#da1f48" cx="275" cy="431.7" r="40.7"></circle>
    <circle fill="#da1f48" cx="275" cy="508" r="20.8"></circle>
    <circle fill="#da1f48" cx="436.3" cy="270.3" r="40.7"></circle>
    <circle fill="#da1f48" cx="512.6" cy="270.3" r="20.8"></circle>
    <circle fill="#da1f48" cx="112.7" cy="270.8" r="40.7"></circle>
    <circle fill="#da1f48" cx="36.4" cy="270.8" r="20.8"></circle>
    <circle fill="#da1f48" cx="275.3" cy="109.1" r="40.7"></circle>
    <circle fill="#da1f48" cx="275.3" cy="32.8" r="20.8"></circle>
  </svg>
)

export default NavLogo
