import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Label, Optional, Error, InputWrapper } from "../"

const Input = styled.input`
  height: 48px;
  width: 100%;
  background: #fff;
  border-color: #ccc;
  border-width: 1px;
  letter-spacing: 0;
  &:disabled {
    background-color: #e9e9e9;
    cursor: not-allowed;
  }
`

export const InputWithLabel = ({
  type,
  label,
  optional,
  name,
  onChange,
  errorText,
  ...props
}) => {
  const handleKeyDown = e => {
    const keys = [13]
    if (keys.includes(e.keyCode)) onChange(e.target.value)
  }
  return (
    <div>
      <Label htmlFor={name}>
        {label} {optional ? <Optional>Optional</Optional> : ""}
      </Label>
      <InputWrapper hasError={!!errorText} theme={props.theme}>
        <Input
          {...props}
          type={type}
          id={name}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

InputWithLabel.defaultProps = {
  type: "text",
  errorText: null,
}

InputWithLabel.propTypes = {
  type: PropTypes.oneOf(["text", "date", "number", "password", "email", "tel"]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
}
