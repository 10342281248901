import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Label, Error, InputWrapper, Row } from "../"

const Max = styled.span`
  text-transform: uppercase;
  font-style: italic;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: ${p => p.theme.colors.grey};
`

const Textarea = styled.textarea`
  width: 100%;
  background: #fff;
  border-color: #ccc;
  border-width: 1px;
  min-height: 100px;
  resize: none;
  line-height: 24px;
  ::placeholder {
    color: ${p => p.theme.colors.grey};
  }
`

export const TextareaWithLabel = ({
  label,
  name,
  placeholder,
  onChange,
  errorText,
  maxLength,
  ...props
}) => {
  return (
    <div {...props}>
      <Row>
        <Label htmlFor={name}>{label}</Label>
        <Max>{maxLength ? `Max ${maxLength} chars` : null}</Max>
      </Row>
      <InputWrapper hasError={!!errorText}>
        <Textarea
          {...props}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

TextareaWithLabel.defaultProps = {
  errorText: null,
  maxLength: null,
  placeholder: null,
}

TextareaWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  maxLength: PropTypes.number,
}
