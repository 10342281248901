import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: proxima-nova, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.25px;
  color: ${p => p.theme.colors.text};
  background-color: #f6f8fb;
  margin: 0;
  position: relative;
}

div {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
}

h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.17px;
  color: #324561;
  text-align: left;
  margin: 0 0 1rem 0;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -1px;
  color: ${p => p.theme.colors.text};
  width: 100%;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

h3 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: ${p => p.theme.colors.text};
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.25px;
  color: ${p => p.theme.colors.text};
}

h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0 12px 0;
  color: ${p => p.theme.colors.text};
}

p {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.25px;
  color: ${p => p.theme.colors.text};
}

a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: ${p => p.theme.colors.text};
}

b, strong {
  font-weight: 600;
}

label {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.54;
  letter-spacing: 1.5px;
  color: ${p => p.theme.colors.text};
}

input, textarea {
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border: solid 1px ${p => p.theme.colors.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: ${p => p.theme.colors.text};
}

.healthcare {
  a:focus, input:focus, textarea:focus, button:focus {
  outline: none;
  /* provide alternative focus state */
  box-shadow: 0 0 0 2px ${p => p.theme.colors.healthcarePrimary};
  z-index: 99;
  }
  .react-select__value-container {
    input:focus {
      box-shadow: none;
    }
  }
}

textarea {
  padding-top: 7px;
  padding-bottom: 7px;
  resize: none;
}

a:focus, input:focus, textarea:focus, button:focus {
  outline: none;
  /* provide alternative focus state */
  box-shadow: 0 0 0 2px ${p => p.theme.colors.primary};
  z-index: 99;
}

hr {
  border: none;
  border-top: 1px solid #dbd8da;
  margin: 2rem 0;
}

img, form {
  width: 100%;
  display: block;
}

ol, ul {
  padding-left: 1rem;
}

svg {
  display: block;
}

li {
  margin: 12px 0 0 0;
}

${p => p.theme.layout.tablet} {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 48px;
    line-height: 1.14;
    letter-spacing: normal;
  }

  h2 {
    font-size: 32px;
    line-height: 1.3;
    letter-spacing: -0.6px;
    margin: 2rem 0;
  }

  h3 {
    font-size: 28px;
    line-height: 1.6;
    margin: 0 0 16px 0;
  }

  h4 {
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 16px 0;
  }

  h5 {
    margin: 0 0 16px 0;
  }
  
  p {
    font-size: 16px;
  }

  ol, ul {
  padding-left: 2rem;
  }
}

${p => p.theme.layout.web} {
  h1 {
    font-size: 54px;
    margin: 0 0 2.5rem 0;
  }

  h2 {
    font-size: 36px;
  }
  
  h3 {
    font-size: 32px;
  }
}



/* other styles */
.sigCanvas {
    width: 100%;
    height: 250px;
  }

  td {
    border: 1px solid #ccc;
    border-bottom: ${p => (p.noBottom ? "none" : "1px solid #ccc")};
    padding: 10px 12px;
    vertical-align: top;
    text-align: left;
  }
  .question-image {
    max-width: 300px;
    margin: 0 auto 1rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`

export default GlobalStyle
