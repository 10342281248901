import React from "react"

const AppContext = React.createContext()

const initialState = {
  email: "",
}

function reducer(state, [type, payload]) {
  switch (type) {
    case "set email":
      return { ...state, email: payload }
    default:
      throw new Error(type)
  }
}

const AppContextProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return <AppContext.Provider value={[state, dispatch]} {...props} />
}

export default AppContext
export { AppContextProvider }
