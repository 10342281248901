import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"
import styled from "styled-components"

import { CheckboxWithLabel } from "."
const StyledCheckbox = styled(CheckboxWithLabel)`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

export const FormikCheckbox = props => {
  const [field, meta, form] = useField(props)
  return (
    <StyledCheckbox
      {...field}
      {...props}
      type="checkbox"
      onChange={v => form.setValue(v ? 1 : 0)}
      onBlur={() => form.setTouched(true)}
      colorTheme={props.colorTheme}
      errorText={meta.touched ? meta.error : ""}
    />
  )
}

FormikCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
