import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"

import Nav from "./ui/nav"
import Footer from "./ui/footer"
import GlobalStyle from "../style/global"
import theme from "../style/theme"
import Login from "./ui/login"
import { AppContextProvider } from "../context"

const Main = styled.main`
  padding: 1rem;
  min-height: 100vh;
  background-color: #f6f8fb;

  ${p => p.theme.layout.tablet} {
    padding: 1.5rem;
  }

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`
const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const Layout = ({ healthcare, children, emailNoCheck }) => {
  const [emailCheck, setEmailCheck] = React.useState(true)

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/rfv7jpp.css" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <GlobalStyle />
          <Nav healthcare={healthcare} />
          {emailCheck && !emailNoCheck ? (
            <Login
              setEmailPassed={() => setEmailCheck(false)}
              healthcare={healthcare}
            />
          ) : null}
          <Main id="main" className={healthcare ? "healthcare" : ""}>
            <Wrapper>{children}</Wrapper>
          </Main>
          <Footer healthcare={healthcare} />
        </AppContextProvider>
      </ThemeProvider>
    </>
  )
}

Layout.defaultProps = {
  healthcare: false,
  emailNoCheck: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  healthcare: PropTypes.bool,
  emailNoCheck: PropTypes.bool,
}

export default Layout
