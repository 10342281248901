import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { InputWithLabel } from "."

export const FormikInput = props => {
  const [field, meta] = useField(props)
  return (
    <InputWithLabel
      {...field}
      {...props}
      errorText={meta.touched ? meta.error : ""}
    />
  )
}

FormikInput.defaultProps = {
  type: "text",
}

FormikInput.propTypes = {
  type: PropTypes.oneOf(["text", "date", "number", "password", "email", "tel"]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
