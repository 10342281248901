import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Label, Error, InputWrapper } from ".."

const TabContainer = styled.div.attrs(() => ({
  className: "tabs",
}))`
  display: flex;

  /* mobile */
  button {
    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }
  }

  button {
    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }
  }

  /*  tablet and up */
  ${p => p.theme.layout.tablet} {
    button {
      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
    }
    button {
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`

const STab = styled.button.attrs(() => ({
  className: "tab",
}))`
  cursor: pointer;
  padding: 13px 18px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${p => (p.active ? "#fff" : p.theme.colors.grey)};
  background: ${p =>
    p.active && p.colorTheme
      ? p.theme.colors.healthcarePrimary
      : p.active
      ? p.theme.colors.primary
      : p.theme.colors.grey_light};
  border: none;

  &:hover {
    filter: brightness(1.025);
  }

  /* mobile  stacked tabs*/
  width: 100%;
  border-bottom-color: ${p =>
    p.active ? p.theme.colors.grey : p.theme.colors.border};

  /*  tablet and up */
  ${p => p.theme.layout.tablet} {
    width: fit-content;
    margin: 3px 0;
    border-right-color: ${p =>
      p.active ? p.theme.colors.grey : p.theme.colors.border};
  }
`

export const TabsWithLabel = ({
  label,
  name,
  tabs,
  setFieldValue,
  errorText,
  colorTheme,
  ...props
}) => {
  const [selected, setSelected] = React.useState(() => props.value)

  const onClick = newSelection => {
    if (newSelection !== selected) {
      setSelected(newSelection)
      setFieldValue(name, newSelection)
    }
  }

  return (
    <div>
      {label !== "" ? <Label>{label}</Label> : ""}
      <TabContainer style={{ flexWrap: "wrap" }}>
        <InputWrapper hasError={!!errorText}>
          {tabs.map(({ name, label }) => (
            <STab
              key={label}
              type="button"
              role="tab"
              className={name === selected ? "tab--active" : ""}
              aria-label={label}
              aria-current={name === selected}
              onClick={() => onClick(name)}
              active={name === selected}
              colorTheme={colorTheme === "healthcare"}
            >
              {label}
            </STab>
          ))}
          <input type="hidden" {...props} name={name} />
        </InputWrapper>
        <Error>{errorText}</Error>
      </TabContainer>
    </div>
  )
}

TabsWithLabel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  colorTheme: PropTypes.string,
}
