import React from "react"
import PropTypes from "prop-types"

const CalendarIcon = props => (
  <svg width="24" height="25" xmlns="http://www.w3.org/2000/svg" {...props}><g fillRule="nonzero"><path d="M20.692 1.953h-1.078V0h-1.797v1.953H5.957V0H4.161v1.953H3.082c-1.486 0-2.695 1.314-2.695 2.93V22.07c0 1.616 1.21 2.93 2.695 2.93h17.61c1.486 0 2.695-1.314 2.695-2.93V4.883c0-1.616-1.209-2.93-2.695-2.93zm.898 20.117c0 .539-.403.977-.898.977H3.082c-.495 0-.898-.438-.898-.977V9.18H21.59v12.89zm0-14.843H2.184V4.883c0-.539.403-.977.898-.977h1.079V5.86h1.796V3.906h11.86V5.86h1.797V3.906h1.078c.495 0 .898.438.898.977v2.344z"/><path d="M3.387 11h2v2h-2zM8.387 11h2v2h-2zM13.387 11h2v2h-2zM18.387 11h2v2h-2zM3.387 15h2v2h-2zM8.387 15h2v2h-2zM13.387 15h2v2h-2zM18.387 15h2v2h-2zM3.387 19h2v2h-2zM8.387 19h2v2h-2zM13.387 19h2v2h-2zM18.387 19h2v2h-2z"/></g></svg>
)

CalendarIcon.defaultProps = {
  height: "20px",
  width: "20px",
}
CalendarIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
}

export default CalendarIcon
