import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Error, InputWrapper } from "../"

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  outline: none;
  &:focus .check {
    outline: none;
    /* provide alternative focus state */
    box-shadow: 0 0 0 2px #f9f9f9;
  }
`

const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: #324561;
  margin-left: 16px;
`

const LabelBold = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: #324561;
  margin-left: 16px;
`

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: ${p =>
    p.theme.colors[
      p.isChecked && p.colorTheme
        ? "healthcarePrimary"
        : p.isChecked
        ? "primary"
        : "bg"
    ]};
  transition: background-color 200ms ease-in-out;

  :hover {
    background: ${p =>
      p.theme.colors[
        p.isChecked && p.colorTheme
          ? "healthcarePrimary"
          : p.isChecked
          ? "primary"
          : "bg"
      ]};
  }
`

const Check = styled.svg`
  width: 14px;
  height: 11px;
  stroke: #fff;
  stroke-width: 2;
  fill: none;
  stroke-linecap: "square";
  opacity: ${p => (p.isChecked ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
`

export const CheckboxGroup = styled.section`
  margin: 0 0 20px 0;

  b {
    display: block;
    margin: 0 0 12px 0;
  }
`

export const CheckboxWithLabel = ({
  bold,
  withoutError,
  errorText,
  label,
  name,
  onChange,
  colorTheme,
  value,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(value)

  const update = newValue => {
    onChange(newValue)
    setIsChecked(newValue)
  }

  const handleKeyDown = e => {
    const keys = [13]
    if (keys.includes(e.keyCode)) update()
  }

  return (
    <div {...props}>
      <Wrapper
        onClick={() => update(!isChecked)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <InputWrapper hasError={!!errorText}>
          <Checkbox
            isChecked={isChecked}
            className="check"
            colorTheme={!!colorTheme}
          >
            <Check
              width="14"
              height="11"
              xmlns="http://www.w3.org/2000/svg"
              isChecked={isChecked}
              role="checkbox"
              aria-checked={isChecked}
              aria-labelledby={name}
            >
              <path d="M12.25 1.75L5.0472 8.9528 2.25 6.1556" />
            </Check>
          </Checkbox>
        </InputWrapper>
        {bold ? (
          <LabelBold id={name}>{label}</LabelBold>
        ) : (
          <Label id={name}>{label}</Label>
        )}
      </Wrapper>
      {withoutError ? "" : <Error>{errorText}</Error>}
    </div>
  )
}

CheckboxWithLabel.defaultProps = {
  value: false,
  colorTheme: "",
}

CheckboxWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOf([true, false, 0, 1]),
  onChange: PropTypes.func.isRequired,
  colorTheme: PropTypes.string,
}
