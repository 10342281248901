import React from "react"
import styled from "styled-components"

import NavLogo from "../../../assets/images/logo"
import HealthCareLogo from "../../../assets/images/healthcare-logo"

const SNav = styled.nav`
  width: 100%;
  background-color: #fff;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  max-width: 1440px;
  margin: 0 auto;

  ${p => p.theme.layout.tablet} {
    padding: 20px;
  }
`
const Brand = styled.div`
  display: flex;
  width: 200px;
`

const Nav = healthcare => {
  return (
    <SNav>
      <Wrapper>
        <Brand>
          <a href="/">
            {healthcare && healthcare.healthcare ? (
              <HealthCareLogo />
            ) : (
              <NavLogo />
            )}
          </a>
        </Brand>
      </Wrapper>
    </SNav>
  )
}

export default Nav
