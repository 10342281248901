import React from "react"
import { useField } from "formik"
import { TabsWithLabel } from "./Tabs"

export const FormikTabs = props => {
  const [field, meta] = useField(props)
  return (
    <TabsWithLabel
      {...field}
      {...props}
      colorTheme={props.colorTheme}
      errorText={meta.touched ? meta.error : ""}
    />
  )
}
