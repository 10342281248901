import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Formik } from "formik"
import * as Yup from "yup"

import { emailCheck } from "../../../services"
import appContext from "../../../context"

import { SCard } from "../../../style/containers"
import SButton from "../../button"
import Form from "../../form/FormHelper"
import { FormikInput, Error } from "../../form"

const Container = styled(SCard)`
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 11;
  justify-content: flex-start;
  width: 80%;
  max-height: 80%;
  overflow-y: scroll;
  ${p => p.theme.layout.tablet} {
    top: 20%;
    left: 33%;
    width: 33%;
  }
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
`

const Login = ({ setEmailPassed, healthcare }) => {
  const [state, dispatch] = React.useContext(appContext)
  const [errorText, setErrorText] = React.useState("")

  const onSubmit = async (values, actions) => {
    let res = await emailCheck(values)
    if (res.error) {
      setErrorText(res.error)
    } else {
      dispatch(["set email", values.email])
      setEmailPassed()
    }
    actions.setSubmitting(false)
  }
  return (
    <>
      <Container>
        <h1>Pin Point Form Portal</h1>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email address")
              .required(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <p>
                Before you begin to fill out this form we must check to see if
                this is your first time using the portal.
              </p>
              <FormikInput
                name="email"
                type="text"
                placeholder="Enter your email"
                label="Enter the email you will use for your application"
              />
              {errorText ? <Error>{errorText}</Error> : null}
              <SButton
                type="submit"
                onClick={() => {}}
                disabled={isSubmitting}
                colorTheme={healthcare ? "healthcare" : ""}
              >
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </Container>
      <Background />
    </>
  )
}

Login.defaultProps = {
  healthcare: false,
}

Login.propTypes = {
  setEmailPassed: PropTypes.func.isRequired,
  healthcare: PropTypes.bool,
}

export default Login
