import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarIcon from '../../../assets/icons/calendar.js'

import { Label, Error, InputWrapper } from '..'
import { parseDateString } from '../../../utils/form-data.js'
import moment from 'moment'

export const SDatePicker = styled(DatePicker)`
  font-size: 1rem;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #ccc;
  appearance: none;
  outline: none;
  position: relative;

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      div {
        .react-datepicker {
          .react-datepicker__month-container {
            .react-datepicker__header {
              background-color: #0344B9;
            }
          }
        }
      }
    }
  }

  .react-datepicker__header {
    background-color: #0344B9;
  }
  .react-datepicker-popper {
    z-index: 50 !important;
  }
`

export const SCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 10px;
  right: 0;
  height: 25px;
  width: 25px;
  fill: #0344B9;
  ${p => p.theme.layout.tablet} {
    margin-left: 1.5rem;
    margin-right: 1rem;
  }
`

export const Datepicker = ({ className, label, name, errorText, minDate, ...props }) => {
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <InputWrapper hasError={!!errorText}>
        <SDatePicker
          {...props}
          id={name}
          calendarClassName="pinpoint-calendar"
          dateFormat="dd/MM/yyyy"
          autoComplete={"off"}
          minDate={minDate ? minDate : new Date("01/01/1900")}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <SCalendarIcon />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

Datepicker.defaultProps = {
  errorText: null,
  className: '',
}

Datepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
}
