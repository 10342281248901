import styled from "styled-components"
import { Form } from "formik"

const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`
const Content = styled.section`
  width: 100%;
`

const Card = styled.div`
  display: flex;
  align-items: center;
  background: white;

  ${p => p.theme.style.box_shadow};
  margin-bottom: 16px;
  border-radius: 8px;
`

const SCard = styled(Card)`
  flex-direction: column;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;

  ${p => p.theme.layout.web} {
    padding: 3rem;
  }
`

const SForm = styled(Form)`
  background-color: #fff;
  padding: 2rem 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`

const LCard = styled(SCard)`
  padding: 1.5rem;

  ${p => p.theme.layout.phone} {
    padding: 2rem;
  }

  ${p => p.theme.layout.tablet} {
    padding: 2.5rem;
  }

  ${p => p.theme.layout.web} {
    padding: 2.5rem 2.75rem;
  }
`

const CardGrey = styled.section`
  width: 100%;
  background-color: ${p => p.theme.colors.grey_light_super};
  padding: 16px 24px;
  border-radius: 8px;

  span,
  b {
    display: block;
  }
`

const Col2 = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem 2.5rem;
  margin-bottom: 2rem;
  width: 100%;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;

    h3,
    h4,
    h5 {
      grid-column: 1 /3;
    }
  }
`

const Col2Constant = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 1.5rem;
  width: 100%;
`

const FieldSet = styled.section`
  display: block;
  width: 100%;
`

const HeaderWithLogo = styled.div`
  display: grid;

  h1 {
    margin: 0;
    order: 2;
  }

  img {
    order: 1;
    max-width: 80px;
    margin: 0 0 16px 0;
  }

  ${p => p.theme.layout.phone} {
    display: grid;
    grid-template-columns: 1fr 140px;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;

    h1 {
      order: 1;
    }

    img {
      order: 2;
      max-width: none;
      margin: 0;
    }
  }
  ${p => p.theme.layout.web} {
    padding: 0 0 0.5rem 0;
  }
`

export {
  Card,
  CardGrey,
  Col2,
  Col2Constant,
  Content,
  FieldSet,
  HeaderWithLogo,
  LCard,
  SCard,
  SForm,
  Wrapper,
}
