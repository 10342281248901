import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled, { ThemeContext } from "styled-components"
import RSelect from "react-select"

import { Label, Error, InputWrapper, Row } from "../"

const SSelect = styled(RSelect)`
  width: 100%;

  input:focus {
    box-shadow: none;
  }

  .react-select__control {
    padding-left: 8px;
    padding-right: 8px;
  }

  .react-select__value-container {
    min-height: 46px;
  }

  .react-select__indicator-separator {
    display: none;
  }
`
const Note = styled.span`
  text-transform: uppercase;
  font-style: italic;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: ${p => p.theme.colors.grey_primary};
  margin-bottom: 12px;
  line-height: 1.54;
`

export const Select = ({
  label,
  name,
  errorText,
  options,
  placeholder,
  onChange,
  note,
  colorTheme,
  ...props
}) => {
  const theme = useContext(ThemeContext)
  const colours =
    colorTheme === "healthcare"
      ? {
          primary: theme.colors.healthcarePrimary,
          primary25: theme.colors.grey_light,
          primary50: theme.colors.grey_light,
        }
      : {
          primary: theme.colors.primary,
          primary25: theme.colors.grey_light,
          primary50: theme.colors.grey_light,
        }

  return (
    <div className={props.className}>
      {label || note ? (
        <Row>
          {label ? <Label htmlFor={name}>{label}</Label> : <div />}
          {note ? <Note>{note}</Note> : null}
        </Row>
      ) : null}

      <InputWrapper hasError={!!errorText}>
        <SSelect
          {...props}
          inputId={name}
          type="select"
          classNamePrefix="react-select"
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          theme={selectTheme => ({
            ...selectTheme,
            borderRadius: "4px",
            colors: {
              ...selectTheme.colors,
              ...colours,
            },
          })}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

Select.defaultProps = {
  errorText: null,
  placeholder: "Please select...",
  note: null,
  colorTheme: "",
  className: "",
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
}
