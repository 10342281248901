import React from "react"
import { Form, useFormikContext } from "formik"

const FormHelper = props => {
  const { isValidating, isValid } = useFormikContext()

  React.useEffect(() => {
    if (!isValidating && !isValid) {
      const element = document.querySelector(".has-error")

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        })
      }
    }
  }, [isValidating, isValid])

  return (
    <Form>
      <React.Fragment {...props} />
    </Form>
  )
}
export default FormHelper
